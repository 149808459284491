import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import notify from 'notify';
import { getListingDetails } from 'apis/listings';
import ListingDetails from 'templates/ListingDetails';

const Listing = ({ location }) => {
  const { pathname } = location;
  let matchId = pathname.replace('/listing/', '');
  const slashIndex = matchId.indexOf('/');

  // eslint-disable-next-line no-bitwise
  if (~slashIndex) matchId = matchId.substring(0, slashIndex);

  const [data, setData] = useState<ListingDetails>();
  const [hasLoad, setHasLoad] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const loadListing = async () => {
    try {
      const data = await getListingDetails(matchId || '');
      setData(data);
      setHasLoad(true);
    } catch (err) {
      setError(`API is not working: ${err.message}`);
      notify(err.message);
      setHasLoad(false);
    }
  };

  useEffect(() => {
    if (matchId) loadListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((!data && hasLoad) || !matchId) navigate('/404');
  }, [data, hasLoad, matchId]);

  if (error) return <>{error}</>;

  return data ? <ListingDetails data={{ listing: data, isDynamic: true }} /> : null;
};

export default Listing;
